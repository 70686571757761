import axios from "axios";

export function APIRequest1(
  requestType,
  baseurl,
  endpoint,
  payload,
  contentType
) {
  const config = {
    method: `${requestType}`,
    url: `${baseurl}${endpoint}`,
    headers: {
      // Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTk5MDQwMDEsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJhYW1pci5tb2hkQGxveXlhbC5jb20iLCJhdWQiOlsiY29yZS1lbmdpbmUtc2VydmljZSIsImNvbnRyYWN0LW1hbmFnZW1lbnQtc2VydmljZSIsImFsbC1sb3l5YWwtZnJvbnRlbmRzIl0sImF1dGhvcml6ZWQiOnRydWUsIm5hbWUiOiJBYW1pciIsInVzZXJuYW1lIjoiYWFtaXIubW9oZEBsb3l5YWwuY29tIiwicm9sZUNvZGUiOiJMT1lZQUxfQURNSU4iLCJyb2xlSWQiOjMsImV4cCI6MTc1MTQ0MDAwMX0.qnwWHrh6YEz9CTjkpN28-pSSRhRYvgZYLDUNEgNbQUegOmteFcEKge_MeEUnJ3A0mJu5ZvXR6Go1Jvit8x3K1dDhBI6km7zFBGkOOZFx1xNKWkU3SggJ2j4JlB_GLTLsTv9-hgG6qUr5qQrcbs4LuBvZrx4G9yZEWtKZ2OwA9sqHHtXBxGkEkzylso63am0-b1DByHhUqW9w63o3HT96m6UZ7QBEncBTeoJ4EnfSgdT45YXFf-lOD5Sz97yYmWRpEi4D210itKhSJLtXr0zxTmCUgtRDBwSPr0UWIfUSrlUFNak6hzNYq-6v-_hBwuUL0H0pzvy7pAtJP7p6FSfIJQ`,
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": contentType ? contentType : "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: payload,
  };

  return axios(config);
}

export function HandleError(err) {
  if (err.response) {
    return `Error: ${
      err.response.data.message ||
      err.response.data.detail ||
      err.response.data?.errorMessage
    }`;
  }
  if (err.request) {
    return `Error: ${err.request}`;
  }
  return `Error: ${err.message}`;
}
