let transferPartnerDataStore: { [key: string]: any } = {};

export function addDataToTransferPartnerDataStore(key: string, value: any) {
    transferPartnerDataStore[key] = value;
}

export function getDataFromTransferPartnerDataStore(key: string, defaultValue?: any) {
    return transferPartnerDataStore[key];
}

export function clearDataFromTransferPartnerDataStore(key: string) {
    transferPartnerDataStore[key] = undefined;
}
