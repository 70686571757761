let programDataStore: { [key: string]: any } = {};

export function addDataToProgramDataStore(key: string, value: any) {
    programDataStore[key] = value;
}

export function getDataFromProgramDataStore(key: string, defaultValue?: any) {
    return programDataStore[key];
}

export function clearDataFromProgramDataStore(key: string) {
    programDataStore[key] = undefined;
}
