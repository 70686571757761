// authContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));
  // const baseUrl = "http://core-engine.loyyal.com:9080";
  const baseUrl = process.env.REACT_APP_CORE_ENGINE_URL;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const login = async (username, password, programCode) => {
    try {
      let loginData = {
        username: username,

        password: password,
        programCode: programCode,
      };

      var config = {
        method: "post",

        url: `${baseUrl}/program/${programCode}/memberLogin`,
        headers: {
          // Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTg5NzA0OTEsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJhYW1pci5tb2hkQGxveXlhbC5jb20iLCJhdWQiOlsiY29yZS1lbmdpbmUtc2VydmljZSIsImNvbnRyYWN0LW1hbmFnZW1lbnQtc2VydmljZSIsImFsbC1sb3l5YWwtZnJvbnRlbmRzIl0sImF1dGhvcml6ZWQiOnRydWUsIm5hbWUiOiJBYW1pciIsInVzZXJuYW1lIjoiYWFtaXIubW9oZEBsb3l5YWwuY29tIiwicm9sZUNvZGUiOiJMT1lZQUxfQURNSU4iLCJyb2xlSWQiOjMsImV4cCI6MTc1MDUwNjQ5MX0.tNuqhQvrVUTVQN1uCYuPB5-llNoLtcTx7Wpe_ss_R50hgzbcU9uYMe96udKwdypRDAaj4dXsksHNH4kqzLQlVf7fNbw4JQSDPhY--4YuJLWPFVCxMpimMRxZkX2TSdm0h78rKenx8j1pqN0okui2v8zu6D4ri_ZIoDO4bPChV_CKcjX_gofT_zygVcbYeQShuoBrpxYs7vO9yYROC6s4LARfoU-wsVAVxshb1okCviVsxinp2LQz4o68_5RKTHRVo42ndDHJUeEBxMBfMnuIq7oVPxP8oUotUzkZAfsErf8yvHIzKWzK6T4jUcRqiCbyJBW2zkmbroMUjBJVWYBXYw`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: loginData,
      };
      const response = await axios(config);
      // if (response.data.jwtToken) {
      //   localStorage.setItem("token", response.data.jwtToken);
      //   setIsLoggedIn(true);
      //   return true;
      // }
      if (response.data.jwtToken) {
        localStorage.setItem("token", response.data.jwtToken);
        // save users details
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        localStorage.setItem("userPoints", response.data.pointBalance);
        //save points also individually
        setIsLoggedIn(true);
        return true;
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
    return false;
  };

  // const login = () => {
  //   try {
  //     const response = {
  //       firstName: "John",
  //       lastName: "Doe",
  //       email: "john@gmail.com",
  //       programCode: "et",
  //       memberId: "12345",
  //       pointBalance: "100000",
  //       jwtToken:
  //         "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTkyOTUxNTMsImlzcyI6ImNvcmUtZW5naW5lLXNlcnZpY2UiLCJzdWIiOiJqb2huQGdtYWlsLmNvbSIsImF1ZCI6WyJjb3JlLWVuZ2luZS1zZXJ2aWNlIiwiY29udHJhY3QtbWFuYWdlbWVudC1zZXJ2aWNlIiwiYWxsLWxveXlhbC1mcm9udGVuZHMiXSwiYXV0aG9yaXplZCI6dHJ1ZSwiZmlyc3ROYW1lIjoiSm9obiIsImxhc3ROYW1lIjoiSm9obiIsImVtYWlsIjoiam9obkBnbWFpbC5jb20iLCJtZW1iZXJJZCI6IjEyMzQ1IiwicHJvZ3JhbUNvZGUiOiJldCIsInBvaW50QmFsYW5jZSI6IjEwMDAwMCIsInJvbGVDb2RlIjoiUFJPR1JBTV9BRE1JTiIsImV4cCI6MTc1MDgzMTE1M30.XT0Wc5UlOJYGcZ_525JNppTVECQLNPGKS_eyZpgvwW-lLP_G0XLvmsI0wfOBrm-HKdOdqN17k2DCIq-MdV4Adnznz8uMSTi_nNLRaFTLeNb_tac6K1kibnkgHTSHdT93NnanWSqYnyxl-2rY4XvCOe7xQyb_DY5bHoBAhT9BdwAzysNcy02UiHExDbdG1_PyLkxu6JPrF2h7AZRIHl4blhdut7rf0tnjqJ5R4xBhOnvPajicGYEzOZiNg-e-MFGbq3cW9P0gZKWSBkvtDZ-GlENivZM-fSMfOaKc39RUTini67nfWLBO6c_eN3b4QomDsPNGz5fIDY8lUHBBDOM7wA",
  //     };
  //     if (response.jwtToken) {
  //       localStorage.setItem("token", response.jwtToken);
  //       setIsLoggedIn(true);
  //       return true;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   return false;
  // };

  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);

    localStorage.removeItem("sessionTemp");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, setIsLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
