import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/app.router";
import "./assets/fonts/index.css";
import theme from "./theme/theme";
import { ThemeProvider } from "@mui/material";
import { AuthProvider } from "./auth/authContext";
import { SnackbarProvider } from "notistack";

function App() {
  useEffect(() => {
    document.title = "Point Transfer"; // Set your static title here
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Router />
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
