import { Navigate, useRoutes } from "react-router-dom";
// import { HomePage } from "../pages/home";
import { PointsTransfer } from "../pages/points-transfer";
import { Login } from "../pages/auth/Login";
import { useAuth } from "../auth/authContext";
import { HomePage } from "../pages/home";

export default function Router() {
  const ProtectedRoute = ({ element }) => {
    // const { isLoggedIn } = useAuth();
    let token = "";
    let sessionToken = "";
    token = localStorage.getItem("token");
    sessionToken = localStorage.getItem("sessionTemp");

    return token || sessionToken ? element : <Navigate to="/" replace />;
  };

  const elements = useRoutes([
    {
      path: "/home-page",
      element: <HomePage />,
    },
    {
      path: "/points-transfer",
      // element: <PointsTransfer />,
      element: <ProtectedRoute element={<PointsTransfer />} />,
    },
    ,
    {
      path: "/",
      element: <Login />,
    },
  ]);

  return elements;
}
